<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="submitData"
        :rules="rulesData"
        ref="formName"
        size="small"
        label-width="80px"
      >
        <el-form-item label="收支类型" prop="SpendType">
          <el-select
            v-model="submitData.SpendType"
            placeholder="请选择收支类型"
            @change="onChangeSpendType"
          >
            <el-option
              v-for="(item, index) in initData.spendType"
              :key="'type_' + item.Value"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!--  -->
        <el-form-item label="收支分类">
          <el-select
            v-model="classify"
            placeholder="请选择收支分类"
            @change="onChangeKindList"
          >
            <el-option
              v-for="(item, index) in kindList"
              :key="item.KindGuid"
              :label="item.KindName"
              :value="item.KindGuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收支项目" prop="ItemGuid">
          <el-select v-model="submitData.ItemGuid" placeholder="请选择收支项目">
            <el-option
              v-for="(item, index) in itemList"
              :key="item.ItemGuid"
              :label="item.ItemName"
              :value="item.ItemGuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收支途径" prop="SpendWay">
          <el-select v-model="submitData.SpendWay" placeholder="请选择收支途径">
            <el-option
              v-for="(item, index) in initData.spendWay"
              :key="'way_' + item.Value"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收支金额" prop="SpendMoney">
          <el-input
            v-model="submitData.SpendMoney"
            placeholder="请输入收支金额"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="付款方式" prop="PaymentGuid">
          <el-select
            v-model="submitData.PaymentGuid"
            placeholder="请选择付款方式"
          >
            <el-option
              v-for="(item, index) in initData.paymentList"
              :key="item.PaymentGuid"
              :label="item.PaymentName"
              :value="item.PaymentGuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经手人" >
          <el-select
            v-model="submitData.EmplGuid"
            placeholder="请选择经手人"
            @change="onChangeEmpl"
          >
            <el-option
              v-for="(item, index) in initData.emplList"
              :key="item.EmplGuid"
              :label="item.EmplName"
              :value="item.EmplGuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" prop="SoftwareDate">
          <el-date-picker
            v-model="submitData.SoftwareDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="submitData.SpendNote"
            type="textarea"
            placeholder="请输入备注"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="onCancelDialog"> 取 消 </el-button>
        <el-button
          ref="submitButton"
          size="small"
          @click="onConfirmDialog"
          type="primary"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../../../api/cashier.js";
export default {
  data() {
    return {
      title: "",
      showDialog: false,
      initData: {},
      submitData: {
        SpendGuid: "",
        SpendType: "",
        ItemGuid: "",
        SpendWay: "",
        SpendMoney: "",
        PaymentGuid: "",
        Handler: "",
        EmplGuid: "",
        SoftwareDate: "",
        SpendNote: "",
      },
      kindList: [],
      itemList: [],
      classify: "",
      rulesData: {
        SpendType: [
          { required: true, message: "请选择收支类型", trigger: "change" },
        ],

        ItemGuid: [
          { required: true, message: "请选择收支项目", trigger: "change" },
        ],

        SpendWay: [
          { required: true, message: "请选择收支途径", trigger: "change" },
        ],

        SpendMoney: [
          { required: true, message: "请输入收支金额", trigger: "blur" },
        ],

        PaymentGuid: [
          { required: true, message: "请选择付款方式", trigger: "change" },
        ],

        Handler: [
          { required: true, message: "请选择经收人", trigger: "change" },
        ],

        EmplGuid: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
        ],

        SoftwareDate: [
          { required: true, message: "请选择日期", trigger: "blur" },
        ],
      },
    };
  },

  methods: {
    async openAddIncomeExpenditure(guid) {
      try {
        let { data } = await api.initSaveData({ spend_guid: guid ? guid : "" });
        this.initData = data;
        if (guid) {
          this.title = "修改收支";
          let { detail } = data;
          console.log(detail);
          this.submitData = {
            SpendGuid: detail.SpendGuid,
            SpendType: detail.SpendType,
            ItemGuid: detail.ItemGuid,
            SpendWay: detail.SpendWay,
            SpendMoney: detail.SpendMoney,
            PaymentGuid: detail.PaymentGuid,
            Handler: detail.Handler,
            EmplGuid: detail.EmplGuid,
            SoftwareDate: this.util.dateFormat(
              "YYYY-mm-dd",
              new Date(detail.SoftwareDate * 1000)
            ),
            SpendNote: detail.SpendNote,
          };
          this.onChangeSpendType(detail.SpendType);
          this.onChangeEmpl(detail.EmplGuid);
          data.itemList.forEach((item) => {
            if (item.ItemGuid == detail.ItemGuid) {
              this.classify = item.KindGuid;
              this.onChangeKindList(item.KindGuid);
            }
          });
        } else {
          this.title = "新增收支";
        }
      } catch (e) {
        //TODO handle the exception
      }

      this.showDialog = true;
    },

    closeAddIncomeExpenditure() {
      this.submitData = {
        SpendGuid: "",
        SpendType: "",
        ItemGuid: "",
        SpendWay: "",
        SpendMoney: "",
        PaymentGuid: "",
        Handler: "",
        EmplGuid: "",
        SoftwareDate: "",
        SpendNote: "",
      };
      this.classify = "";
      this.showDialog = false;
    },

    onCancelDialog() {
      this.$refs["formName"].resetFields();
      this.closeAddIncomeExpenditure();
    },

    onConfirmDialog() {
      try {
        this.$refs["formName"].validate(async (valid) => {
          if (valid) {
            this.$refs.submitButton.disabled = await true;
            let { data } = await api.saveSpend(this.submitData);
            await this.$emit("addIncomeExpenditureSuccess");
            await this.$refs["formName"].resetFields();
            await this.closeAddIncomeExpenditure();
            await this.$message({
              message:
                this.submitData.SpendGuid == "" ? "添加成功!" : "修改成功",
              type: "success",
            });
          } else {
            return false;
          }
          this.$refs.submitButton.disabled = await false;
        });
      } catch (e) {}
    },

    onChangeSpendType(event) {
      let tArray = [];
      this.initData.kindList.forEach((item) => {
        if (item.SpendType == event) {
          tArray.push(item);
        }
      });
      this.kindList = tArray;
    },

    onChangeKindList(event) {
      let tArray = [];
      this.initData.itemList.forEach((item) => {
        if (item.KindGuid == event) {
          tArray.push(item);
        }
      });
      this.itemList = tArray;
    },

    onChangeEmpl(event) {
      this.initData.emplList.forEach((item) => {
        if (item.EmplGuid == event) {
          this.submitData.Handler = item.EmplName;
        }
      });
    },
  },
};
</script>

<style lang="less">
.el-select {
  width: 100%;
}

.el-date-editor {
  width: 100% !important;
}

.el-form {
  padding: 0 50px;
}
</style>