import { request } from "../utils/axios";

export default {
	// 水单审单-获取
	getAuditingList(params = {}){
		return request('get',`/auditing/get_auditing_list`, params);
	},
	
	// 水单审单-初始获取条件
	getInitData(params = {}){
		return request('get',`/auditing/get_init_data`, params);
	},
	
	// 水单审单-对单
	handle(params = {}){
		return request('get',`/auditing/handle`, params);
	},
	
	// 水单审单-销单
	reverseBill(params = {}){
		return request('get',`/auditing/reverse_bill`, params, false, true, false, true);
	},
	
	// 水单审单-记账日期
	alterSoftwareSate(params = {}){
		return request('post',`/auditing/alter_software_date`, params);
	},
	
	// 水单审单-修改备注
	updateNote(params = {}){
		return request('post',`/auditing/update_note`, params);
	},
	
	// 水单审单-修改计客数
	updateCalcClient(params = {}){
		return request('post',`/auditing/update_calc_client`, params);
	},
	
	// 水单审单-添加消费员工
	addExpeEmpl(params = {}){
		return request('post','/auditing/add_expe_empl', params);
	},
	
	// 
	updateExpePerforRoya(params = {}){
		return request('post','/auditing/update_expe_perfor_roya?subact=change_perfor', params);
	},

	// 水单审单-修改消费项目部门
	updateExpeItemDept(params = {}){
		return request('post','/auditing/update_expe_item_dept', params);
	},
	
	updateExpeRoya(params = {}){
		return request('post','/auditing/update_expe_perfor_roya?subact=change_roya', params);
	},
	
	updateExpeAssign(params = {}){ 
		return request('post','/auditing/update_expe_perfor_roya?subact=change_assign_mark', params);
	},
	
	updateExpeAssignRoya(params = {}){
		return request('post','/auditing/update_expe_perfor_roya?subact=change_assign_roya', params);
	},
	
	updateExpeInventRoya(params = {}){
		return request('post','/auditing/update_expe_perfor_roya?subact=change_invent_roya', params);
	},
	
	deleteExpeEmpl(params = {}){
		return request('post','/auditing/delete_expe_empl', params);
	},
  
	calculateKeepEmpl(params = {}){
		return request('get','/auditing/calculate_keep_empl', params);
	},
	
	addKeepEmpl(params = {}){
		return request('post','/auditing/add_keep_empl', params);
	},
	
	updateKeepEmplPerfor(params = {}){
		return request('post','/auditing/update_keep_empl?subact=change_perfor', params);
	},
	
	updateKeepEmplRoya(params = {}){
		return request('post','/auditing/update_keep_empl?subact=change_roya', params);
	},
	
	deleteKeepEmpl(params = {}){
		return request('get','/auditing/delete_keep_empl', params);
	},

	initOverallOpenKeepRoya(params = {}){
		return request('get','/auditing/init_overall_open_keep_roya', params);
	},

	saveOverallOpenKeepRoya(params = {}){
		return request('post','/auditing/save_overall_open_keep_roya', params);
	},

	// 水单审单 ===> 关联时间记录
	
	getRelateTimeList(params = {}){
		return request('get','/auditing/get_relate_time_list', params);
	},

	relateBillToTime(params = {}){
		return request('get','/auditing/relate_bill_to_time', params);
	},
	
	getSpendTree(params = {}){
		return request('get','/spend/get_spend_tree', params);
	},

	initSpendData(params = {}){
		return request('get','/spend/init_spend_data', params);
	},
	
	initSaveData(params = {}){
		return request('get','/spend/init_save_data', params);
	},
	
	initQueryData(params = {}){
		return request('get','/spend/init_query_data', params);
	},
	
	getSpendMaintList(params = {}){
		return request('get','/spend/get_spend_maint_list', params);
	},
	
	initSpendKindData(params = {}){
		return request('get','/spend/init_spend_kind_data', params);
	},
	
	addSpendKind(params = {}){
		return request('post','/spend/add_spend_kind', params);
	},
	
	updateSpendKind(params = {}){
		return request('post','/spend/update_spend_kind', params);
	},
	
	deleteSpendKind(params = {}){
		return request('get','/spend/delete_spend_kind', params);
	},
	
	initSpendItemData(params = {}){
		return request('get','/spend/init_spend_item_data', params);
	},
	
	addSpendItem(params = {}){
		return request('post','/spend/add_spend_item', params);
	},
	
	saveSpendItem(params = {}){
		return request('post','/spend/save_spend_item', params);
	},
	
	updateSpendItem(params = {}){
		return request('post','/spend/update_spend_item', params);
	},
	
	deleteSpendItem(params = {}){
		return request('get','/spend/delete_spend_item', params);
	},
	
	saveSpend(params = {}){
		return request('post','/spend/save_spend', params);
	},
	
	deleteSpend(params = {}){
		return request('get','/spend/delete_spend', params);
	}
}