<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="formData"
        :rules="rulesData"
        ref="formName"
        size="small"
        label-width="80px"
      >
        <el-form-item label="分类名称" prop="KindName">
          <el-input v-model="formData.KindName" placeholder="请输入分类名称">
          </el-input>
        </el-form-item>
        <el-form-item label="收支类型" prop="SpendType">
          <el-select
            v-model="formData.SpendType"
            placeholder="请选择收支类型"
            :disabled="isDisabled"
          >
            <el-option
              v-for="(item, index) in initComponentData"
              :key="index"
              :value="item.Value"
              :label="item.Name"
            >
              {{ item.Name }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="onCancelDialog"> 取 消 </el-button>
        <el-button
          ref="submitButton"
          size="small"
          @click="submitForm"
          type="primary"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../../../api/cashier.js";
export default {
  data() {
    return {
      showDialog: false,
      formData: {
        KindName: "",
        SpendType: "",
        KindGuid: "",
      },
      rulesData: {
        KindName: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
        ],
        SpendType: [
          { required: true, message: "请选择收支类型", trigger: "change" },
        ],
      },
      initComponentData: [],
      isDisabled: true,
      title: "",
    };
  },

  methods: {
    async openAddClassify(event, kindGuid) {
      console.log(event);
      // 添加
      if (event) {
        if (event.NodeGuid == "InCome") {
          this.formData.SpendType = 2;
        } else if (event.NodeGuid == "Expend") {
          this.formData.SpendType = 1;
        }
      }

      // 修改
      if (kindGuid) {
        this.formData.KindGuid = kindGuid;
        this.formData.KindName = event.NodeName;
        this.formData.SpendType = event.SpendType;
        this.title = "修改分类";
      } else {
        this.title = "新增分类";
      }

      try {
        let { data } = await api.initSpendKindData();
        this.initComponentData = data;
      } catch (e) {
        //TODO handle the exception
      }
      this.showDialog = true;
    },

    closeAddClassify() {
      this.$refs["formName"].resetFields();
      this.showDialog = false;
      this.formData = {
        KindName: "",
        SpendType: "",
        KindGuid: "",
      };
    },

    onCancelDialog() {
      this.closeAddClassify();
    },

    submitForm() {
      this.$refs["formName"].validate(async (valid) => {
        if (valid) {
          try {
            this.$refs.submitButton.disabled = await true;
            if (await this.formData.KindGuid) {
              let { data, errcode, errmsg } = await api.updateSpendKind(
                this.formData
              );
              if (errcode == (await 0)) {
                await this.$message({
                  message: "修改成功!",
                  type: "success",
                });
                await this.$emit("addClassifySuccess");
                await this.closeAddClassify();
              } else {
                await this.$message.error(errmsg);
              }
            } else {
              let { data, errcode, errmsg } = await api.addSpendKind(
                this.formData
              );
              if (errcode == (await 0)) {
                await this.$message({
                  message: "添加成功!",
                  type: "success",
                });
                await this.$emit("addClassifySuccess");
                await this.closeAddClassify();
              } else {
                await this.$message.error(errmsg);
              }
            }
            this.$refs.submitButton.disabled = await false;
          } catch (e) {
            console.log(e);
            //TODO handle the exception
          }
        } else {
          return false;
        }
      });
    },

    onConfirmDialog() {
      this.closeAddClassify();
    },
  },
};
</script>

<style>
</style>